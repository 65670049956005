import PropTypes from 'prop-types'
import { compose } from 'redux'
import Button from '@mui/material/Button'
import { Fragment } from 'react'
import { asPercentageString } from 'fp/numbers'
import { fallbackTo, isDefined } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import withProps from 'hoc/withProps'
import StandardMasteryDialog from './StandardMasteryDialog'
import RequiresGradingIndicator from './RequiresGradingIndicator'

const StandardScoreCell = ({ cell: { renderValue } }) => {
  const cellValue = renderValue() || {}
  const { requiresGrading, score } = cellValue
  const [isDialogOpen, toggleIsDialogOpen] = useToggleState()
  const MaybeButton = (isDefined(score) && !Number.isNaN(score))
    ? withProps(Button, { onClick: toggleIsDialogOpen, variant: 'tertiary' })
    : Fragment

  return (
    <>
      <MaybeButton>
        {compose(fallbackTo(), asPercentageString)(score)}
        {Boolean(requiresGrading) && <RequiresGradingIndicator />}
      </MaybeButton>

      {Boolean(isDialogOpen) && (
        <StandardMasteryDialog
          onClose={toggleIsDialogOpen}
          {...cellValue}
        />
      )}
    </>
  )
}

StandardScoreCell.propTypes = {
  cell: PropTypes.shape({
    renderValue: PropTypes.func.isRequired,
  }).isRequired,
}

export default StandardScoreCell
