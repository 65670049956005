import { useContext, useEffect, useState } from 'react'
import { compose } from 'redux'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { interactiveGradingContext } from 'sections/contentBlocks/Interactive/InteractiveGradingProvider'
import { set } from 'fp/objects'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import { curryRight, isDefined, when } from 'fp/utils'
import TextField from 'common/formControls/textInputs/TextField'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import { isEmptyString } from 'fp/strings'
import { asFloatWithoutTrailingZeros, assertRange } from 'fp/numbers'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { componentShape } from 'core/shapes'
import { items } from 'hss/views/Library/AddToLibrary/assetTypes/Interactives'
import { findObj, isEmpty } from 'fp/arrays'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { interactiveContext } from '../../InteractiveProvider'

const Container = styled('div')({
  p: { width: '100%' },
  '.MuiTextField-root': { width: 100 },
})
const ManualOverrideGrader = ({ CustomRenderer }) => {
  const {
    saveButtonDisabled,
    scoreData,
    setSaveButtonDisabled,
    setScoreData,
  } = useContext(interactiveGradingContext)
  const { currentUserAssignmentId } = useContext(userAssignmentSelectionContext)

  const {
    Renderer,
    gradingEnabled,
    interactive: { contentSubType, name, scoring: { maxScore, maxScoreOverride, mode: scoringMode } },
  } = useContext(interactiveContext)

  const { manualScoreOverride, score = 0 } = scoreData || { }

  const round = num => Math.round(num * 100) / 100

  // `maxScore` and `maxScoreOverride` are whole-point int values
  const maxScoreToUse = isDefined(maxScoreOverride)
    ? maxScoreOverride
    : maxScore

  const overrideValue = isDefined(manualScoreOverride) ? round(manualScoreOverride * maxScoreToUse) : ''

  const [scoreOverrideEnabled, setScoreOverrideEnabled] = useState()
  const [scoreToUse, setScoreToUse] = useState()

  const handleScoreChange = ({ target: { value } }) => {
    const overrideScore = isEmptyString(value)
      ? null
      : compose(
        curryRight(assertRange, 0, maxScoreToUse),
        round,
        parseFloat,
      )(value) / maxScoreToUse
    setSaveButtonDisabled(false)
    setScoreData(set('manualScoreOverride', overrideScore))
  }

  const handleOverrideToggle = ({ target: { checked } }) => {
    setScoreOverrideEnabled(checked)
    // reset the manual override score to null the switch is toggled to null
    if (!checked && isDefined(manualScoreOverride) && manualScoreOverride !== null) {
      setScoreData(set('manualScoreOverride', null))
      setSaveButtonDisabled(false)
    }
  }

  // if manual override is defined, then toggle the switch on
  useEffect(() => {
    when((isDefined(manualScoreOverride) && manualScoreOverride !== null), setScoreOverrideEnabled, true)
  }, [manualScoreOverride])

  // when the user assignment changes, reset
  useEffect(() => {
    setScoreOverrideEnabled(false)
    setSaveButtonDisabled(true)
  }, [currentUserAssignmentId, setSaveButtonDisabled])

  // If `scoreData` is populated, calculate and set "Total Points Awarded" value
  useEffect(() => {
    if (!isEmpty(scoreData) && saveButtonDisabled) {
      // `score` and `manualScoreOverride` are decimal percentage values between 0 and 1 (ex: 0.25)
      const updatedScore = isDefined(manualScoreOverride)
        ? manualScoreOverride
        : score
      const calculatedScore = asFloatWithoutTrailingZeros(updatedScore * maxScoreToUse, 2)
      setScoreToUse(calculatedScore)
    }
  }, [manualScoreOverride, maxScoreToUse, saveButtonDisabled, score, scoreData])

  const allowManualScoring = scoringMode
    && scoringMode !== SCORING_MODE_NONE

  const label = name || findObj('contentSubType', contentSubType)(items)?.label

  const isSmallScreen = useContainerQuery().down('sm')

  const InteractiveRenderer = CustomRenderer || Renderer

  return (
    <Container>

      <InteractiveRenderer />

      {allowManualScoring
        ? (
          <>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <Typography
                data-testid={`${contentSubType}-total-points`}
                variant="small-semibold"
              >
                Total Points Awarded: {scoreToUse}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            >
              <SwitchFormControl
                checked={scoreOverrideEnabled}
                data-testid={`${contentSubType}-override-grade`}
                disabled={!gradingEnabled}
                helperText="When enabled, you can manually set the score of this interactive."
                label="Override grade"
                onChange={handleOverrideToggle}
              />
              {Boolean(scoreOverrideEnabled) && (
                <TextField
                  inputProps={{
                    'aria-label': `Score for ${label}`,
                    min: 0,
                    max: maxScoreToUse,
                  }}
                  label="Score"
                  name="override"
                  onChange={handleScoreChange}
                  type="number"
                  value={overrideValue}
                  variant="filled"
                />
              )}
            </div>
          </>
        )
        : null}
    </Container>
  )
}

ManualOverrideGrader.propTypes = {
  CustomRenderer: componentShape,
}

export default ManualOverrideGrader
