import { compose } from 'redux'
import { styled, useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { darken } from 'polished'
import { useContext } from 'react'
import { fallbackTo, matches } from 'fp/utils'
import { fallsWithin } from 'fp/numbers'
import useCurrentUser from 'hooks/useCurrentUser'
import { get, pick } from 'fp/objects'
import { ASSIGNMENT_STARTED, ASSIGNMENT_SUBMITTED, ASSIGNMENT_UNSTARTED_PAST_DUE } from 'core/consts'
import { gradebookContext } from './GradebookContextProvider'

const asPercentage = value => Number.isNaN(value) ? 0 : Math.round(value * 100)
const shouldUseColor = [ASSIGNMENT_UNSTARTED_PAST_DUE, ASSIGNMENT_SUBMITTED]

const StyledCell = styled(
  'td',
  { name: 'GradebookTableCell' },
)(({
  gradebookColor: { backgroundColor, hoverColor, textColor },
  gradingStatus,
  theme: { palette },
}) => ({
  backgroundColor,
  color: textColor,

  ...gradingStatus === ASSIGNMENT_STARTED && {
    a: {
      textDecoration: 'none',
    },
  },

  '.MuiButtonBase-root.MuiButton-root': {
    backgroundColor,
    color: textColor,

    '&:hover, &:focus': {
      transition: 'all 250ms ease-in-out 0ms',
      backgroundColor: hoverColor,
      color: palette.grey.contrastText,

      a: {
        textDecorationThickness: '0.3rem',
      },
    },
  },

  ...shouldUseColor.includes(gradingStatus) && {
    '&:hover, &:focus': {
      transition: 'all 250ms ease-in-out 0ms',
      backgroundColor: hoverColor,
      color: palette.grey.contrastText,

      a: {
        textDecorationThickness: '0.3rem',
      },
    },
  },
}))

const GradebookCellWrapper = (props) => {
  const { palette: { common, gradebook } } = useTheme()
  const { user: { preferences: { gradeColors: { segmentColors, defaultColor } } } } = useCurrentUser()
  const { view } = useContext(gradebookContext)

  const { cell, children, className } = props
  const cellValue = cell.renderValue()
  const { gradingStatus, score } = cellValue
  const getGradingColor = (id) => {
    if (id === 'none') {
      return {
        value: common.white,
        textColor: common.black,
      }
    }

    return gradebook.find(matches('colorId', id))
  }

  const defaultGradingColor = getGradingColor(defaultColor)
  let scoreGradingColor = getGradingColor('none')

  if (shouldUseColor.includes(gradingStatus) || (view === 'standards' && (score !== undefined && score !== null))) {
    scoreGradingColor = compose(
      fallbackTo(defaultGradingColor),
      colorId => getGradingColor(colorId),
      get('colorId'),
      finalScore => segmentColors.find(({ begin, end }) => fallsWithin(finalScore, begin, end)),
      fallbackTo(0),
      compose(
        item => view === 'standards' ? asPercentage(item.score) : asPercentage(item.score / item.maxScore),
        pick(['score', 'maxScore']),
      ),
    )(cellValue)
  }

  return (
    <StyledCell
      className={className}
      gradebookColor={{
        backgroundColor: scoreGradingColor.value,
        textColor: scoreGradingColor.textColor,
        hoverColor: scoreGradingColor.hoverColor || darken(0.3, scoreGradingColor.value),
      }}
      gradingStatus={gradingStatus}
    >
      {children}
    </StyledCell>
  )
}

GradebookCellWrapper.propTypes = {
  cell: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default GradebookCellWrapper
