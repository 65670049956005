import { useContext } from 'react'
import Grid from '@mui/material/Grid'
import Pagination from 'common/tables/DataTable/Pagination'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { context } from '../SearchProvider'

const Results = () => {
  const {
    ItemRenderer,
    dataset,
    setSelectedPreview,
    setSquery,
    squery,
    tableId,
    topOfSearchDomRef: scrollToResultsRef,
    viewMode,
  } = useContext(context)
  const { only } = useContainerQuery()
  const handleClick = result => () => {
    setSelectedPreview(result)
  }
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)

  const oneRecord = dataset?.data.length === 1

  const gridSizes = viewMode === 'list'
    ? { xs: 12 }
    : oneRecord
      ? { xs: 12, md: 12, xl: 3 }
      : only('xs')
        ? { xs: 12 }
        : only('sm')
          ? { sm: 6 }
          : only('md')
            ? { md: 4 }
            : { lg: 3 }

  return (
    <>
      <Grid
        container
        spacing={3}
      >

        {dataset?.data.map(content => (
          <Grid
            item
            key={content.id}
            {...gridSizes}
          >
            <ItemRenderer
              content={content}
              contentCreator={isContentCreator}
              handlePreview={handleClick(content)}
            />
          </Grid>
        ))}

      </Grid>

      <Pagination
        {...{
          tableId,
          dataset,
          setSquery,
          scrollToResultsRef,
          squery,
        }}
      />
    </>
  )
}

export default Results
