import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ToggleButton from '@mui/material/ToggleButton'
import { componentShape } from 'core/shapes'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import CustomIcon from '../Subsection/Toolbar/CustomIcon'

const MenuItemButton = (props) => {
  const {
    ButtonIcon,
    children,
    disableResponsive,
    disabled,
    forceMenuItem,
    menuItemVariant,
    onClick,
    selected,
    text,
    title,
    value,
    ...rest
  } = props
  const isSmallScreen = useContainerQuery().down('sm')

  return forceMenuItem || (isSmallScreen && !disableResponsive) ? (
    <MenuItem
      aria-label={title || text}
      disabled={disabled}
      onClick={onClick}
      selected={!disabled}
      variant={menuItemVariant}
    >
      <>
        <CustomIcon ButtonIcon={ButtonIcon}>
          {children}
        </CustomIcon>
        <Box ml={2}>{text}</Box>
      </>
    </MenuItem>
  ) : (
    <Tooltip title={title || text}>
      {/* Adding this in-between div so Tooltip has a non-disabled element to attach to */}
      <div>
        <ToggleButton
          data-subvariant="toolbutton"
          disabled={disabled}
          onClick={onClick}
          selected={selected}
          value={value}
          variant="toolbutton"
          {...rest}
        >
          {ButtonIcon ? (
            <CustomIcon ButtonIcon={ButtonIcon}>
              {children}
            </CustomIcon>
          ) : (
            children
          )}
        </ToggleButton>
      </div>
    </Tooltip>
  )
}

MenuItemButton.propTypes = {
  ButtonIcon: componentShape,
  children: componentShape,
  disabled: PropTypes.bool,
  disableResponsive: PropTypes.bool,
  forceMenuItem: PropTypes.bool,
  menuItemVariant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default MenuItemButton
