import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { createElement, useMemo } from 'react'
import { compose } from 'redux'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import FormSection from 'common/layout/FormSection'
import { curryRight, fallbackTo, not } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import { get, set } from 'fp/objects'
import { filter } from 'fp/arrays'
import useContent from 'hooks/useContent'
import { getFlattenedChildrenOfTypes } from 'selectors/content'
import { CONTENT_TYPE_ECHO } from 'core/consts'
import General from './General'
import PeerReview from './PeerReview'
import Customize from './Customize'
import StudentSupport from './StudentSupport'
import AnswerKey from './AnswerKey'
import Contents from './Contents'
import { baseAssignment } from './utils'
import Callout from './Callout'

const customizeSections = [
  {
    Component: Contents,
    title: 'Contents',
  },
  {
    Component: StudentSupport,
    title: 'Student Support',
  },
  {
    Component: AnswerKey,
    title: 'Answer Key',
  },
]

const propsInCustomizeSections = customizeSections.flatMap(compose(fallbackTo([]), get('Component.formFields')))

const pickPropsInCustomizeSections = values => propsInCustomizeSections.reduce(
  (result, nextPath) => {
    const nextValue = get(nextPath)(values)
    return set(nextPath, nextValue)(result)
  },
  {},
)

const Sections = ({ isNewAssignment }) => {
  const { getValues } = useFormContext()
  const content = useContent({ disableFetch: true }) || {}
  const hasEcho = useSelector(getFlattenedChildrenOfTypes({ contentTypes: [CONTENT_TYPE_ECHO] })(content))?.length

  const isCustomizedInitially = useMemo(
    () => compose(
      not,
      curryRight(isEqual, pickPropsInCustomizeSections(baseAssignment)),
      pickPropsInCustomizeSections,
    )(getValues()),
    [getValues],
  )

  const [customizeAssignment, toggleCustomizeAssignment] = useToggleState(isCustomizedInitially)

  const sections = filter(Boolean)([
    {
      Component: General,
      title: 'General',
    },
    hasEcho && {
      Component: PeerReview,
      title: 'Echo Peer Review',
    },
    {
      Component: Customize,
      customizeAssignment,
      title: 'Customize',
      toggleCustomizeAssignment,
    },
    isNewAssignment && {
      Component: Callout,
      title: '',
      key: 'Callout',
    },
    ...(customizeAssignment ? customizeSections : []),
  ])

  return sections.map(({ key, title, Component, ...rest }) => createElement(
    FormSection,
    { key: title || key, title },
    <Component {...rest} />,
  ))
}

Sections.propTypes = {
  isNewAssignment: PropTypes.bool.isRequired,
}

export default Sections
