import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import { useMemo, useState } from 'react'
import TabList from 'common/layout/TabList'
import { getUserAssignment } from 'selectors/userAssignments'
import useAnnotations from 'hooks/useAnnotations'
import { getContextualAssignment } from 'selectors/assignments'
import { ABILITY_TEACHER_INTERFACE } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import Pane from '../Pane'
import SubsectionAnnotations from './SubsectionAnnotations'

const STUDENT = 'student'
const TEACHER = 'teacher'

const AllAnnotations = ({ hideHeader = false }) => {
  const userIsTeacher = useAbilityCheck(ABILITY_TEACHER_INTERFACE)
  const [currentTab, setCurrentTab] = useState(STUDENT)
  const { id: teacherAssignmentId } = useSelector(getContextualAssignment) || {}
  const { assignmentId: studentAssignmentId, id } = useSelector(getUserAssignment) || {}
  const assignmentId = teacherAssignmentId || studentAssignmentId

  const sharedAssignmentId = userIsTeacher || currentTab === TEACHER
    ? assignmentId
    : null
  const userAssignmentId = !userIsTeacher && currentTab === STUDENT
    ? id
    : null

  const { annotations, subsectionsWithAnnotations } = useAnnotations({
    sharedAssignmentId,
    userAssignmentId,
  })

  const Header = useMemo(() => (hideHeader
    ? ({ children }) => <div>{children}</div>
    : ({ children }) => <Pane title="All Annotations">{children}</Pane>
  ), [hideHeader])

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue)
  }

  const subsectionAnnotations = useMemo(
    () => subsectionsWithAnnotations.map((subsection, idx) => (
      <SubsectionAnnotations
        annotations={annotations[subsection.id]}
        content={subsection}
        isLast={idx === subsectionsWithAnnotations.length - 1}
        key={subsection.id}
      />
    )),
    [annotations, subsectionsWithAnnotations],
  )

  return (
    <Header>
      {userIsTeacher ? (
        subsectionAnnotations
      ) : (
        <>
          <TabContext
            minSize="sm"
            value={currentTab}
          >
            <TabList
              aria-label="content and attributes"
              minSize="sm"
              onChange={handleTabChange}
            >
              <Tab
                label={STUDENT}
                value={STUDENT}
              />

              <Tab
                label={TEACHER}
                value={TEACHER}
              />

            </TabList>
          </TabContext>
          {subsectionsWithAnnotations?.length > 0 ? (
            subsectionAnnotations
          ) : (
            <p>No {currentTab} annotations at this time.</p>
          )}
        </>
      )}
    </Header>
  )
}

AllAnnotations.propTypes = {
  hideHeader: PropTypes.bool,
}

export default AllAnnotations
