import PropTypes from 'prop-types'
import { useContext } from 'react'
import AnnotationOffIcon from 'hss/images/controls/menu/annotate-off.svg'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import MenuItemButton from 'hss/ContentViewer/Chapter/Toolbar/MenuItemButton'
import { componentShape } from 'core/shapes'

const AnnotationsOnOffButton = (props) => {
  const {
    ButtonIcon = AnnotationOffIcon,
    forceMenuItem,
    selected,
    text = 'Disable Annotations',
  } = props
  const {
    allowAnnotations,
    toggleAnnotations,
  } = useContext(contentViewerContext)

  return allowAnnotations && (
    <MenuItemButton
      ButtonIcon={ButtonIcon}
      forceMenuItem={forceMenuItem}
      menuItemVariant={selected ? 'selected' : undefined}
      onClick={toggleAnnotations}
      selected={selected}
      text={text}
      value="annotations"
    />
  )
}

AnnotationsOnOffButton.propTypes = {
  ButtonIcon: componentShape,
  forceMenuItem: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string,
}

export default AnnotationsOnOffButton
