import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import hash from 'object-hash'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { Info } from 'react-feather'
import { actions } from 'reducers/interactions'
import actionTypes from 'reducers/actionTypes'
import useReduxCallback, { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import { INTERACTION_TYPE_FEEDBACK } from 'core/consts'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { getInteractionByType } from 'selectors/interactions'
import { getUserAssignmentById } from 'selectors/userAssignments'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import Subheader from 'hss/sections/contentBlocks/Interactive/Subheader'
import IconCallout from 'common/text/IconCallout'
import { getContextualAssignment } from 'selectors/assignments'
import { when } from 'fp/utils'

const statusMessage = {
  [IDLE]: '',
  [BUSY]: 'Submitting feedback…',
  [SUCCESS]: 'Feedback has been submitted.',
  [ERROR]: 'Error submitting feedback.',
}

const FeedbackTab = () => {
  const dispatch = useDispatch()
  const { currentUserAssignmentId: userAssignmentId } = useContext(userAssignmentSelectionContext) || {}
  const { userId } = useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { contentId } = useSelector(getContextualAssignment) || {}

  const [dispatchSave, status] = useReduxCallback({
    actionType: actionTypes.INTERACTION_POST,
  })
  const [feedbackValue, setFeedbackValue] = useState()
  const [showStatus, setShowStatus] = useState()
  const [contentKey, setContentKey] = useState()
  const [disableHandleChange, setDisableHandleChange] = useState(false)

  const { id: interactionId, interactionData: { value: feedbackInteractionValue } } = useSelector(getInteractionByType({
    type: INTERACTION_TYPE_FEEDBACK,
    userAssignmentId,
  })) || { interactionData: {} }

  const handleChange = ({ target: { value } }) => {
    when(!disableHandleChange, setFeedbackValue, value)
  }

  const handleSave = () => {
    dispatchSave({
      contentId,
      contextContentId: contentId,
      interactionData: { value: feedbackValue },
      interactionType: INTERACTION_TYPE_FEEDBACK,
      userAssignmentId,
      userId,
      id: interactionId,
    })
  }

  useEffect(() => {
    dispatch(actions.fetchInteractions({ interactionType: INTERACTION_TYPE_FEEDBACK, userAssignmentId }))
  }, [contentId, dispatch, userAssignmentId])

  useEffect(() => {
    setDisableHandleChange(true)
    // TODO: Make this less ugly after the June 1st deadline
    setTimeout(() => {
      setFeedbackValue(feedbackInteractionValue)
      setShowStatus(false)
      setContentKey(hash({ feedbackInteractionValue }))
      setTimeout(() => {
        setDisableHandleChange(false)
      }, 100)
    }, 100)
  }, [feedbackInteractionValue])

  useEffect(() => {
    setShowStatus(Boolean(statusMessage[status]))
  }, [status])

  return (
    <>
      {Boolean(showStatus) && (
        <Subheader
          backgroundColor="cobalt.4"
          style={{ marginTop: 0 }}
        >
          <IconCallout Icon={<Info />}>
            {statusMessage[status]}
          </IconCallout>
        </Subheader>
      )}
      <Typography
        style={{ marginTop: '40px', textTransform: 'uppercase' }}
        variant="h3"
      >
        My Feedback
      </Typography>
      <Box>Provide written feedback for the student.</Box>
      <RichTextEdit
        contentKey={contentKey}
        excludeToolButtons={['Superscript', 'Subscript', 'Link', 'Highlight', 'Format']}
        onChange={handleChange}
        placeholder="Add feedback here…"
        showStatus
        sx={{
          '.public-DraftEditorPlaceholder-root': {
            width: 'auto',
          },
          '.draft-statusbar > div': {
            opacity: showStatus ? 1 : 0,
            transition: 'all 250ms linear',
          },
        }}
        value={feedbackValue || ''}
      />
      <Box textAlign="end">
        <Button
          color="primary"
          onClick={handleSave}
          variant="secondary"
        >
          Save Feedback
        </Button>
      </Box>
    </>
  )
}

export default FeedbackTab
