import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { useContext, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { compose } from 'redux'
import Typography from '@mui/material/Typography'
import { omit, set } from 'fp/objects'
import { INTERACTION_STATE_COMPLETED, INTERACTIVE_TYPE_CHART_COMPLETION, INTERACTIVE_TYPE_DGO_TABLE } from 'core/consts'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../InteractiveProvider'
import { interactiveGradingContext } from '../InteractiveGradingProvider'

const GradingWrapper = ({ children }) => {
  const { interactive: { contentSubType }, onInteract, scoreData: savedScoreData } = useContext(interactiveContext)
  const { saveButtonDisabled, scoreData, setSaveButtonDisabled, setScoreData } = useContext(interactiveGradingContext)

  useEffect(
    () => {
      setSaveButtonDisabled(true)
      setScoreData(savedScoreData)
    },
    [savedScoreData, setSaveButtonDisabled, setScoreData],
  )

  const handleSave = () => {
    onInteract(compose(
      omit('interactiveData'), // grading should not touch student's response
      set('scoreData', omit('score')(scoreData)), // api calculates actual score
      set('state', INTERACTION_STATE_COMPLETED), // if not already completed, prevent student from updating response
    ))
  }

  return (
    <Stack
      gap={2}
      maxWidth={
        [INTERACTIVE_TYPE_CHART_COMPLETION, INTERACTIVE_TYPE_DGO_TABLE].includes(contentSubType)
          ? undefined
          : featuredContentMaxWidth
      }
      mx="auto"
    >
      {children}
      <hr style={{ margin: '2rem 0' }} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="paragraph">
          {/* TODO: Graded timestamp */}
        </Typography>
        <Button
          color="primary"
          data-testid={`${contentSubType}-save-grade`}
          disabled={saveButtonDisabled}
          onClick={handleSave}
          variant="secondary"
        >
          Save Grade
        </Button>
      </Stack>
    </Stack>
  )
}

GradingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GradingWrapper
