import Info from 'common/layout/Info'
import AnnotationsToggleIcon from 'hss/images/controls/menu/annotations-toggle.svg'

const Callout = () => (
  <Info
    color="primary"
    icon={<AnnotationsToggleIcon />}
    sxProps={{
      alignItems: 'center',
      border: '1px solid',
      borderColor: 'emerald.0',
      height: '100%',
      justifyContent: 'center',
    }}
  >
    You must create the assignment before applying annotations.
  </Info>
)

export default Callout
