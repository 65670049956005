import { useContext, useEffect, useId, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { getEchoRubric } from 'selectors/echo'
import CommentInput from 'common/formControls/textInputs/CommentInput'
import withDebounce from 'hoc/withDebounce'
import { hideForScreen } from 'styling/theming/base/mixins'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import actionTypes from 'reducers/actionTypes'
import ReviewAccordion from 'common/reviews/ReviewAccordion'
import useEchoReactions from './useEchoReactions'
import useStaticRenderer from './useStaticRenderer'

const DebouncedCommentInput = withDebounce()(CommentInput)

const CurrentReview = () => {
  const { subsection: echo } = useContext(contentViewerContext) || {}
  const rubricContent = useSelector(getEchoRubric(echo)) || {}
  const rubricCriteria = rubricContent?.children[0]?.data
  const { responseType } = echo.data
  const id = useId()

  const [rating, setRating] = useState(null)
  const [comment, setComment] = useState('')

  const { pending } = useEchoReactions()

  useEffect(() => {
    setComment('')
    setRating(null)
  }, [pending?.peerInteractionId, pending?.userInteractionId])

  const [submitReaction, submissionsStatus] = useReduxCallback({ actionType: actionTypes.REACTION_POST })
  const busy = submissionsStatus === BUSY

  const StaticRenderer = useStaticRenderer({ responseType })

  const handleSubmit = () => {
    const { peerInteractionId, userInteractionId } = pending
    submitReaction({
      peerInteractionId,
      reactionData: {
        rubricSelections: [
          rating,
        ],
        comment,
      },
      userInteractionId,
    })
  }

  return pending
    ? (
      <>

        <ReviewAccordion
          accordionProps={{ color: 'primary', expanded: true }}
          accordionSummaryProps={{ 'data-subvariant': 'single-item' }}
          onChange={setRating}
          ratingOrRatingId={rating}
          render={() => (
            <StaticRenderer
              pending={pending}
              reaction={pending}
              readOnly
            />
          )}
          rubricCriteria={rubricCriteria}
          title="Anonymous"
        />

        <DebouncedCommentInput
          disabled={busy}
          id={id}
          label="Optional Comment..."
          name="comment"
          onChange={({ target }) => setComment(target.value)}
          value={comment}
        />

        <Button
          color="secondary"
          disabled={busy || !rating}
          onClick={handleSubmit}
          variant="secondary"
        >
          Submit Rating
        </Button>

      </>
    )
    /**
     * We MUST call render() on every render cycle, even if we're not showing
     * anything to review. This is because the hooks within the wrapping provider
     * must be called in the same order on every render cycle.
     *
     * Wrapping it with `hideForScreen()` ensures that the DOM is not polluted
     * with a potentially half-baked render.
     */
    : <Box sx={hideForScreen()}><StaticRenderer /></Box>
}

export default CurrentReview
