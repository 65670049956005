import { styled } from '@mui/material/styles'
import { useContext, useMemo } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { INTERACTIVE_TYPE_CHART_COMPLETION, INTERACTIVE_TYPE_DGO_TABLE } from 'shared/consts'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { fallbackTo, isDefined } from 'fp/utils'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import { get } from 'fp/objects'
import { featuredContentMaxWidth } from 'styling/theming/base'
import withProps from 'hoc/withProps'
import { componentShape } from 'core/shapes'
import { first } from 'fp/arrays'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import { interactiveContext } from '../InteractiveProvider'
import { AnswerKeyContextProvider } from '../../interactives/answerKeyUtils'
import { getRegisteredAggregateViews, getRegisteredAnswerKeyViews, getRegisteredInteractiveGraders } from './utils'
import GradingWrapper from './GradingWrapper'
import NoAggregate from './AggregateViews/NoAggregate'
import AverageScoreAggregate from './AggregateViews/AverageScoreAggregate'

const registeredAnswerKeyViews = getRegisteredAnswerKeyViews()
const registeredGraders = getRegisteredInteractiveGraders()
const registeredAggregateViews = getRegisteredAggregateViews()

const MaxWidthBox = styled(Box)({
  maxWidth: featuredContentMaxWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
})

const TeacherComponentOrDefault = ({ Component, DefaultView, contentSubType }) => Component
  ? [INTERACTIVE_TYPE_CHART_COMPLETION, INTERACTIVE_TYPE_DGO_TABLE].includes(contentSubType)
    ? <Component />
    : <MaxWidthBox><Component /></MaxWidthBox>
  : <DefaultView />

TeacherComponentOrDefault.propTypes = {
  Component: componentShape,
  DefaultView: componentShape.isRequired,
  contentSubType: PropTypes.string,
}

const InteractiveRenderer = ({ DefaultViewComponent, ...rest }) => {
  const DefaultView = useDeepCompareMemo(
    () => withProps(DefaultViewComponent, rest),
    [rest],
  )

  const {
    assignmentId,
    interactive: {
      contentSubType,
      scoring: { mode: scoringMode },
    },
  } = useContext(interactiveContext)

  const isScoreable = isDefined(scoringMode) && scoringMode !== SCORING_MODE_NONE
  const isGradingUserAssignment = compose(
    Boolean,
    get('currentUserAssignmentId'),
    fallbackTo({}),
    useContext,
  )(userAssignmentSelectionContext)

  // Memoizing the view so that it doesn't get repeatedly recreated/remounted,
  // which was causing a lot of flickering.
  return useMemo(
    () => {
      const AggregateViewComponent = (() => {
        const { Component, scoreableOnly, showAverageScore } = registeredAggregateViews[contentSubType] || {}
        return (isScoreable
          ? showAverageScore
            ? withProps(AverageScoreAggregate, { children: <Component /> })
            : Component
          : scoreableOnly
            ? undefined
            : Component) || NoAggregate
      })()
      const AnswerKeyViewComponent = registeredAnswerKeyViews[contentSubType]
      const GradingComponent = registeredGraders[contentSubType]

      const TeacherView = withProps(TeacherComponentOrDefault, { DefaultView, contentSubType })
      const gradingView = isScoreable
        ? <GradingWrapper><TeacherView Component={GradingComponent} /></GradingWrapper>
        : <TeacherView Component={GradingComponent} />

      const aggregateView = <TeacherView Component={AggregateViewComponent} />
      const answerKeyView = (
        <AnswerKeyContextProvider><TeacherView Component={AnswerKeyViewComponent} /></AnswerKeyContextProvider>
      )

      /**
       * For grading and aggregates, there must be a registered component for the interactive type.
       * If there isn't one, then that interactive type doesn't support grading/aggregate view.
       * Answer keys are different. Many interactives' default views behave/display differently
       * for answer key mode. See also comments in utils.js in this folder.
       */
      const USE_THE_DEFAULT = 'USE_THE_DEFAULT'
      const possibleViews = [
        isGradingUserAssignment && (GradingComponent ? gradingView : USE_THE_DEFAULT),
        assignmentId && registeredAggregateViews[contentSubType] && aggregateView,
        isScoreable && answerKeyView,
        USE_THE_DEFAULT,
        // no need to check presenter mode, because this component shouldn't be used at all in presenter mode
      ]
      const result = first(possibleViews.filter(Boolean))
      return result === USE_THE_DEFAULT
        ? <DefaultView />
        : result
    },
    [DefaultView, assignmentId, contentSubType, isGradingUserAssignment, isScoreable],
  )
}

InteractiveRenderer.propTypes = {
  DefaultViewComponent: componentShape.isRequired,
}

export default InteractiveRenderer
