import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { Link as LinkIcon } from 'react-feather'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import { useCallback, useContext } from 'react'
import { compose } from 'redux'
import AnnotationsToggleIcon from 'hss/images/controls/menu/annotations-toggle.svg'
import LineReaderIcon from 'hss/images/controls/menu/line-reader.svg'
import AnnotateViewIcon from 'hss/images/controls/menu/annotate-view.svg'
import AnnotateTeacherIcon from 'hss/images/controls/menu/annotate-teacher.svg'
import AnnotationOffIcon from 'hss/images/controls/menu/annotate-off.svg'
import NotebookIcon from 'hss/images/controls/menu/notebook.svg'
import useToggleState from 'hooks/useToggleState'
import { ABILITY_STUDENT_INTERFACE, ABILITY_TEACHER_INTERFACE, TOGGLE_STATE_LINE_READER_VISIBLE } from 'core/consts'
import MenuItemButton from 'hss/ContentViewer/Chapter/Toolbar/MenuItemButton'
import useIsLargeEnoughForPinning from 'routing/TraverseRoute/useIsLargeEnoughForPinning'
import Dialog from 'common/dialogs/Dialog'
import Notebook from 'hss/sections/Assignments/Notebook'
import AllAnnotations from 'hss/sections/Assignments/Annotations/AllAnnotations'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { getContextualAssignment } from 'selectors/assignments'
import MenuButton from 'common/menus/MenuButton'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { not, when } from 'fp/utils'
import { getAnnotationsForAssignment } from 'selectors/interactions'
import { isEmpty } from 'fp/arrays'
import AnnotationsOnOffButton from './AnnotationsOnOffButton'

const MiscMenuItems = () => {
  const has = useAbilityChecker()
  const navigate = useNavigate()
  const {
    annotationsOn,
    setStudentViewingTeacherAnnotations,
    studentViewingTeacherAnnotations,
    toggleAnnotations,
  } = useContext(contentViewerContext)
  const location = useLocation()
  const isLargeEnoughForPinning = useIsLargeEnoughForPinning()
  const isSmallScreen = useContainerQuery().down('sm')
  const [showLineReader, toggleShowLineReader] = useToggleState(false, TOGGLE_STATE_LINE_READER_VISIBLE)
  const [showAnnotationsDialog, toggleShowAnnotationsDialog] = useToggleState(false)
  const [showNotebookDialog, toggleShowNotebookDialog] = useToggleState(false)
  // const [createNavigationLink, toggleCreateNavigationLink] = useToggleState(false)
  const { id: assignmentId } = useSelector(getContextualAssignment) || {}
  const hasTeacherInterface = has(ABILITY_TEACHER_INTERFACE)
  const hasStudentInterface = has(ABILITY_STUDENT_INTERFACE)
  const teacherViewingAssignment = assignmentId && hasTeacherInterface

  const searchParams = new URLSearchParams(location.search)
  const paneParam = searchParams.get('pane')
  const annotationsSelected = paneParam === 'annotations'
  const notebookSelected = paneParam === 'notebook'

  const handleClick = useCallback((pane) => {
    if ((pane === 'annotations' && annotationsSelected) || (pane === 'notebook' && notebookSelected)) {
      navigate(`${location.pathname}`)
    } else {
      navigate(`${location.pathname}?pane=${pane}`)
    }
  }, [annotationsSelected, location.pathname, navigate, notebookSelected])

  const handleAnnotationsClick = useCallback(() => {
    if (isLargeEnoughForPinning) {
      handleClick('annotations')
    } else {
      toggleShowAnnotationsDialog()
    }
  }, [handleClick, isLargeEnoughForPinning, toggleShowAnnotationsDialog])

  const handleNotebookClick = () => {
    if (isLargeEnoughForPinning) {
      handleClick('notebook')
    } else {
      toggleShowNotebookDialog()
    }
  }

  const enableAnnotations = useCallback((viewTeacherAnnotations) => {
    setStudentViewingTeacherAnnotations(viewTeacherAnnotations)
    when(!annotationsOn, toggleAnnotations)
  }, [annotationsOn, setStudentViewingTeacherAnnotations, toggleAnnotations])

  const AnnotationMenuIcon = annotationsOn
    ? studentViewingTeacherAnnotations ? AnnotateTeacherIcon : AnnotationsToggleIcon
    : AnnotationOffIcon

  const annotationsAvailable = hasStudentInterface || teacherViewingAssignment

  const AllAnnotationsBtn = useCallback(() => (
    <MenuItemButton
      ButtonIcon={AnnotateViewIcon}
      forceMenuItem={isSmallScreen}
      onClick={handleAnnotationsClick}
      selected={annotationsSelected}
      text={`${annotationsSelected ? 'Hide' : 'Open'} All Annotations`}
      value="annotations"
    />
  ), [annotationsSelected, handleAnnotationsClick, isSmallScreen])

  const ViewStudentAnnotationsBtn = useCallback(() => (
    <MenuItemButton
      ButtonIcon={AnnotationsToggleIcon}
      forceMenuItem={!isSmallScreen}
      menuItemVariant={!studentViewingTeacherAnnotations && annotationsOn ? 'selected' : undefined}
      onClick={() => enableAnnotations(false)}
      selected={!studentViewingTeacherAnnotations}
      text="My Annotations"
      value="student-annotations"
    />
  ), [annotationsOn, enableAnnotations, isSmallScreen, studentViewingTeacherAnnotations])

  const hasTeacherAnnotations = compose(
    not,
    isEmpty,
    useSelector,
    getAnnotationsForAssignment,
  )({ sharedAssignmentId: assignmentId })

  const ViewTeacherAnnotationsBtn = useCallback(() => (
    <MenuItemButton
      ButtonIcon={AnnotateTeacherIcon}
      disabled={!hasTeacherAnnotations}
      forceMenuItem={!isSmallScreen}
      menuItemVariant={studentViewingTeacherAnnotations && annotationsOn ? 'selected' : undefined}
      onClick={() => enableAnnotations(true)}
      selected={studentViewingTeacherAnnotations}
      text={`Teacher Annotations${ hasTeacherAnnotations ? '' : ' (none)'}`}
      value="teacher-annotations"
    />
  ), [annotationsOn, enableAnnotations, hasTeacherAnnotations, isSmallScreen, studentViewingTeacherAnnotations])

  return (
    <>
      {Boolean(hasStudentInterface) && (
        <MenuItemButton
          ButtonIcon={LineReaderIcon}
          onClick={toggleShowLineReader}
          selected={showLineReader}
          text={`${showLineReader ? 'Hide' : 'Show'} Line Reader`}
          value="linereader"
        />
      )}
      {Boolean(isSmallScreen && annotationsAvailable) && (
        <>
          {Boolean(hasStudentInterface) && <ViewStudentAnnotationsBtn />}
          {Boolean(hasStudentInterface) && <ViewTeacherAnnotationsBtn />}
          {Boolean(hasStudentInterface) && <AnnotationsOnOffButton selected={!annotationsOn} />}
          {Boolean(!hasStudentInterface) && (
            <AnnotationsOnOffButton
              ButtonIcon={AnnotationMenuIcon}
              selected={annotationsOn}
              text="Annotations"
            />
          )}
          <AllAnnotationsBtn />
        </>
      )}
      {Boolean(hasStudentInterface) && (
        <MenuItemButton
          ButtonIcon={NotebookIcon}
          onClick={handleNotebookClick}
          selected={notebookSelected}
          text={`${notebookSelected ? 'Hide' : 'Open'} Notebook`}
          value="notebook"
        />
      )}
      {Boolean(showAnnotationsDialog) && (
        <Dialog
          onClose={toggleShowAnnotationsDialog}
          open
          showCloseButton
          title="All Annotations"
        >
          <DialogContent>
            <AllAnnotations hideHeader />
          </DialogContent>
        </Dialog>
      )}
      {Boolean(showNotebookDialog) && (
        <Dialog
          onClose={toggleShowNotebookDialog}
          open
          showCloseButton
          title="Notebook"
        >
          <DialogContent>
            <Box
              mb={2}
              mt={1}
            >
              <Notebook hideHeader />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {Boolean(!isSmallScreen && annotationsAvailable) && (
        <>
          {hasStudentInterface ? (
            <MenuButton
              ButtonControl={ToggleButton}
              icon={<AnnotationMenuIcon />}
              label="Annotations"
              selected={annotationsOn}
              value="toolbar-menu"
              variant="toolbutton"
            >
              {Boolean(hasStudentInterface) && <ViewStudentAnnotationsBtn />}
              {Boolean(hasStudentInterface) && <ViewTeacherAnnotationsBtn />}
              <AnnotationsOnOffButton
                forceMenuItem
                selected={!annotationsOn}
              />
            </MenuButton>
          ) : (
            <AnnotationsOnOffButton
              ButtonIcon={AnnotationMenuIcon}
              selected={annotationsOn}
              text="Annotations"
            />
          )}
          <AllAnnotationsBtn forceMenuItem />
        </>
      )}

      {/* TODO: Need to make this functional */}
      {/* <MenuToggleButton
        onClick={toggleCreateNavigationLink}
        selected={createNavigationLink}
        value="navigation-link"
      >
        <LinkIcon style={{ color: getColor(createNavigationLink) }} />
      </MenuToggleButton> */}
    </>
  )
}

export default MiscMenuItems
