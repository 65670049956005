import { compose } from 'redux'
import { alter, assert } from 'core/store/search/squery'
import { get, set } from 'fp/objects'
import { restEndpoint } from 'reducers/utils'
import { studentsUrl } from 'routing/consts'
import {
  LanguageSelectTableCell,
  LinkToTableCell,
  ProficiencySelectTableCell,
  RowDetailsToggle,
  RowSelector,
  SimpleCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import { prefix } from 'fp/strings'
import {
  firstNameCell,
  lastNameCell,
  lastOnlineCell,
  nextDueDateCell,
  numGroupsCell,
  numTeacherIdsCell,
  openAssignmentsCell,
  proficiencyCell,
  viewStudentGradebookCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import DataTable from 'common/tables/DataTable'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import withSaving from 'common/tables/DataTable/Cells/withSaving'
import { FEATURE_FLAG_POST_MVP, ROLE_STUDENT } from 'core/consts'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import useAbilityChecker from 'hooks/useAbilityChecker'

const tableId = 'clStudentsList'

const buildColumns = (has) => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  return [

    postMVP && RowSelector({ singleLabel: 'Select Student', pluralLabel: 'Select all Students' }),

    asPrimary(set('cell', LinkToTableCell(compose(
      prefix(`${studentsUrl}/`),
      get('id'),
    ))))(firstNameCell()),

    asPrimary(lastNameCell()),

    viewStudentGradebookCell,

    set('cell', ProficiencySelectTableCell)(proficiencyCell),

    lastOnlineCell,

    asDetail(openAssignmentsCell),

    asDetail(numGroupsCell),

    postMVP && asDetail(SimpleCell({
      cell: withSaving('users')(LanguageSelectTableCell),
      header: 'Supplemental Language',
      id: 'languageId',
    })),

    asDetail(nextDueDateCell),

    asDetail(numTeacherIdsCell),

    RowDetailsToggle(),

  ]
}

const searchConfig = (has, limit) => ({

  columns: buildColumns(has),

  initialSquery: compose(
    alter.set.modifier('withMetadata').is(true),
    setDefaultLimit(limit),
    alter.set.where('roleId').is(ROLE_STUDENT),
  )(assert()),

  pluralLabel: 'Students',

  restEndpoint: restEndpoint.students,

  searchFields: ['firstName', 'lastName'],

  singleLabel: 'Student',

})

const withSearchProvider = (WrappedComponent) => {
  const Enhanced = (initialTestingState) => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table"
      >
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
