import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useId, useState } from 'react'
import { compose } from 'redux'
import { styled } from '@mui/material/styles'
import { groupAndSortGroupShape } from 'core/shapes'
import { filter, orderBy } from 'fp/arrays'
import { SCORING_RESULT_CORRECT } from 'core/consts'
import { SCORING_MODE_NONE, interactiveVariants } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { fallbackTo, matches } from 'fp/utils'
import { get } from 'fp/objects'
import { context } from './context'
import AddToGroupDialog from './AddToGroupDialog'
import GroupContent from './GroupContent'

const getAnsweredOptions = {
  [interactiveVariants.NORMAL]: (groupId, options, answers) => {
    // get all of the original options for this group, in their original order
    const filteredOptions = compose(
      orderBy('sortOrder'),
      filter(matches('groupId', groupId)),
    )(options)

    // get all of the user answers for this group that were not correct
    const invalidOptions = filter(option => option.droppedId === groupId
      && answers[option.id] !== SCORING_RESULT_CORRECT)(options)

    const answersAreAvailable = Object.keys(answers).length > 0

    return filteredOptions.map((option) => {
      const isCorrect = answers[option.id] === SCORING_RESULT_CORRECT

      const actualAnswer = isCorrect
        ? option.label
        : invalidOptions.pop()?.label || (answersAreAvailable ? '(Blank)' : '')

      return {
        actualAnswer,
        isCorrect,
        ...option,
      }
    })
  },
  [interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY]: (groupId, options) => {
    const groupOptions = compose(
      orderBy('displayIndex'),
      filter(matches('droppedId', groupId)),
    )(options)
    return groupOptions.map(option => ({
      ...option,
      actualAnswer: option.label,
    }))
  },
}

const StyledBox = styled(Box)(({ theme: { mixins: { borderS }, palette, typography: { variants } } }) => ({
  ...borderS(palette.border[0]),
  ...variants['nav-item-uppercase-semibold'],
  color: palette.text.secondary,
  background: palette.background.paper,
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  marginTop: -1,
  marginLeft: -1,
  flex: 0,
}))

const Group = ({ group, idx }) => {
  const { completed, showGroupTotals, state: { options } } = useContext(context)
  const ctx = useContext(interactiveContext)
  const variant = compose(fallbackTo(interactiveVariants.NORMAL), get('interactiveData.variant'))(ctx)
  const [groupOptions, setGroupOptions] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    const answers = get('scoreData.result.byItemId')(ctx) || {}
    const scoringMode = get('interactive.scoring.mode')(ctx) || SCORING_MODE_NONE
    const newOptions = (completed && scoringMode !== SCORING_MODE_NONE)
      // get all of the original answers with the user answers included where applicable
      ? getAnsweredOptions[variant](group.id, options, answers || {})
      // get the items that have been dropped on this group
      : options.filter(option => option.droppedId === group.id)

    setGroupOptions(newOptions)
  }, [completed, ctx, group.id, options, variant])

  const headingId = useId()

  const groupTotals = `(${groupOptions.length} of ${group.totalItems})`

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <StyledBox
          height="100%"
          id={headingId}
          textAlign="center"
        >
          <Typography
            variant="nav-item-uppercase-semibold"
            width="100%"
          >
            {group.heading}
            {Boolean(showGroupTotals && !completed) && <span>{` ${ groupTotals}`}</span>}
          </Typography>
        </StyledBox>

        <GroupContent
          group={group}
          groupIdx={idx}
          groupOptions={groupOptions}
          headingId={headingId}
          setDialogOpen={setDialogOpen}
        />
      </Box>

      <AddToGroupDialog
        dialogOpen={dialogOpen}
        group={group}
        setDialogOpen={setDialogOpen}
      />
    </>
  )
}

Group.propTypes = {
  group: groupAndSortGroupShape.isRequired,
  idx: PropTypes.number.isRequired,
}

export default Group
